import React, { useEffect, useState } from "react";
import Axios from "axios";
import toastr from "toastr";
import { Helmet } from "react-helmet";

// Import Swiper React components
// import { Swiper, SwiperSlide } from "swiper/react";
// import { Navigation } from "swiper";
import map from "./../../../src/animations/map-1.json";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import Lottie from "lottie-react";
// import required modules
import { Autoplay } from "swiper";
// import { NavLink } from "react-router-dom";

const Contactus = () => {
  const [Firstname, setFirstname] = useState("");
  const [FirstnameErr, setFirstnameErr] = useState("");
  const [Lastname, setLastname] = useState("");
  const [LastnameErr, setLastnameErr] = useState("");
  const [email, setEmail] = useState("");
  const [emailErr, setemailErr] = useState("");
  const [Phone, setPhone] = useState("");
  const [phoneErr, setphoneErr] = useState("");
  const [Industry, setIndustry] = useState("");
  const [IndustryErr, setIndustryErr] = useState("");
  const [Message, setMessage] = useState("");
  const [MessageErr, setMessageErr] = useState("");
  const [countries, setCountries] = useState([]);
  const [selectedcountries, setselectedcountries] = useState([]);
  const [launchperiod, setlaunchperiod] = useState([]);
  const [Services, setServices] = useState([]);

  const [isITChecked, setIsITChecked] = useState(false);
  const [isMAChecked, setIsMAChecked] = useState(false);
  const [isDSChecked, setIsDSChecked] = useState(false);
  const [isBOChecked, setIsBOChecked] = useState(false);
  const [isNFTChecked, setIsNFTChecked] = useState(false);
  const [isMChecked, setIsMChecked] = useState(false);
  const [isBTChecked, setIsBTChecked] = useState(false);

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    // Fetch the list of countries from the API
    Axios.get("https://blogapi.chakravuyha.com/api/user/getCountries")
      .then((response) => {
        setCountries(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  // checkbox functions
  const handleITCheckboxChange = () => {
    setIsITChecked(!isITChecked);
    if (isITChecked == true) {
      setServices.push("IT");
    } else {
    }
  };
  const handleMACheckboxChange = () => {
    setIsMAChecked(!isMAChecked);
  };
  const handleDSCheckboxChange = () => {
    setIsDSChecked(!isDSChecked);
  };
  const handleBOCheckboxChange = () => {
    setIsBOChecked(!isBOChecked);
  };
  const handleNFTCheckboxChange = () => {
    setIsNFTChecked(!isNFTChecked);
  };
  const handleMCheckboxChange = () => {
    setIsMChecked(!isMChecked);
  };
  const handleBTCheckboxChange = () => {
    setIsBTChecked(!isBTChecked);
  };
  const handleLaunchpadChange = (event) => {
    setlaunchperiod(event.target.value);
  };
  const handlecountryesChange = (event) => {
    debugger;
    setselectedcountries(event.target.value);
  };

  const [selectedLabels, setSelectedLabels] = useState([]);
  const checkboxOptions = [
    "Information Technology",
    "Mobile Application",
    "DevOps Solutions",
    "Business Outsourcing",
    "Non-fungible tokens (NFTs)",
    "Metaverse",
    "Blockchain Technology",
  ]; // Replace with your options

  const handleCheckboxChange = (label) => {
    // Check if the label is already in the selectedLabels array
    if (selectedLabels.includes(label)) {
      setSelectedLabels(selectedLabels.filter((item) => item !== label));
    } else {
      setSelectedLabels([...selectedLabels, label]);
    }
  };

  const handleChange = (event) => {
    // console.log("---->", event.target.name, event.target.value);
    if (event.target.name === "Firstname") {
      if (event.target.value.length <= 2) {
        setFirstnameErr("Firstname must be min 2 letters");
      } else {
        setFirstnameErr("");
      }

      setFirstname(event.target.value);
    } else if (event.target.name === "Lastname") {
      if (event.target.value.length <= 2) {
        setLastnameErr("Lastname must be min 2 letters");
      } else {
        setLastnameErr("");
      }

      setLastname(event.target.value);
    } else if (event.target.name === "email") {
      let reg = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/i;
      if (reg.test(event.target.value)) {
        setemailErr("");
      } else {
        setemailErr("please enter correct email");
      }
      setEmail(event.target.value);
    } else if (event.target.name === "Phone") {
      let reg = /^(?:\(\d{3}\)|\d{3})[- ]?\d{3}[- ]?\d{4}$/;
      if (reg.test(event.target.value)) {
        setphoneErr("");
      } else {
        setphoneErr("please enter correct Phone Number");
      }
      setPhone(event.target.value);
    } else if (event.target.name === "Industry") {
      if (event.target.value.length <= 2) {
        setIndustryErr("Industry name must be min 2 letters");
      } else {
        setIndustryErr("");
      }

      setIndustry(event.target.value);
    } else if (event.target.name === "Message") {
      if (event.target.value.length <= 10) {
        setMessageErr("Message must be min 10 letters");
      } else {
        setMessageErr("");
      }

      setMessage(event.target.value);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (
      Firstname === "" ||
      FirstnameErr !== "" ||
      Lastname === "" ||
      LastnameErr !== "" ||
      email === "" ||
      emailErr !== "" ||
      Phone === "" ||
      phoneErr !== "" ||
      Industry === "" ||
      IndustryErr !== "" ||
      Message === "" ||
      MessageErr !== ""
    ) {
      alert("Please fill all input fields");
      return;
    }
    // api call

    const url = "https://blogapi.chakravuyha.com/api/user/send-email";
    const body = {
      firstnmae: Firstname,
      lastname: Lastname,
      email: email,
      country: selectedcountries,
      Industry: Industry,
      service: selectedLabels,
      launchperiod: launchperiod,
      comments: Message,
    };
    const headerTypes = {
      "content-type": "application/json",
    };
    Axios.post(url, body, { headers: headerTypes })
      .then((res) => {
        toastr.success(res.data.message);
      })
      .catch((error) => {
        toastr.error(error.data.message);
      });
  };
  return (
    <>
      <Helmet>
        <title>Contact-Us</title>
        <meta name="Let us reach out to you" content="Nested component" />
      </Helmet>
      <div className="contactus-main">
        <div className="topblur-image">
          <img
            className="righttop-blur"
            src="./images/right-top.png"
            alt="no-data"
          />
        </div>
        <div className="leftblur-image">
          <img className="left-blur" src="./images/left.png" alt="no-data" />
        </div>
        <div className="rightblur-image">
          <img className="right-blur" src="./images/right.png" alt="no-data" />
        </div>
        <div className="contactus-top"></div>
        <div className="contactus-content">
          <div className="container">
            <div className="getstartdata">
              <h3 className="getstart-title">Get Started</h3>
              <p className="getstart-para">
                CST 3.0 - Let’s bring your product idea to life in a
                month.&nbsp;&nbsp; Build DEXs | Wallets | Marketplaces | Stable
                Coins | Metaverses | Mobile Apps | IT Solutions
              </p>
              <p className="getstart-para">
                Help us with the below form, and let us reach out to you
              </p>
            </div>
            <div className="row justify-content-center">
              <div className="col-md-10">
                <form className="contact-forms" onSubmit={handleSubmit}>
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <div>
                        <label className="name-lables">First Name</label>
                        <input
                          type="text"
                          name="Firstname"
                          className="form-control text-fiealds"
                          onChange={handleChange}
                        />
                      </div>
                      {FirstnameErr ? (
                        <p className="text-danger">{FirstnameErr}</p>
                      ) : null}
                    </div>
                    <div className="form-group col-md-6">
                      <div>
                        <label className="name-lables">Last Name</label>
                        <input
                          type="text"
                          className="form-control text-fiealds"
                          name="Lastname"
                          onChange={handleChange}
                        />
                        {LastnameErr ? (
                          <p className="text-danger">{LastnameErr}</p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <div>
                        <label className="name-lables">Work Email</label>
                        <input
                          type="email"
                          className="form-control text-fiealds"
                          name="email"
                          onChange={handleChange}
                        />
                        {emailErr ? (
                          <p className="text-danger">{emailErr}</p>
                        ) : null}
                      </div>
                    </div>
                    <div className="form-group col-md-6">
                      <div>
                        <label className="name-lables">Mobile Number</label>
                        <input
                          type="number"
                          className="form-control text-fiealds text-fiealds1"
                          name="Phone"
                          onChange={handleChange}
                        />
                        {phoneErr ? (
                          <p className="text-danger">{phoneErr}</p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label className="name-lables">Country</label>
                      <select
                        className="form-control text-fiealds"
                        onChange={handlecountryesChange}
                      >
                        {countries.map((country) => (
                          <option value={country.name} key={country.name}>
                            {country.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="form-group col-md-6">
                      <div>
                        <label className="name-lables">Industry</label>
                        <input
                          type="text"
                          className="form-control text-fiealds text-fiealds1"
                          name="Industry"
                          onChange={handleChange}
                        />
                        {IndustryErr ? (
                          <p className="text-danger">{IndustryErr}</p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label className="name-lables">Services</label>
                      <ul className="check-content">
                        {checkboxOptions.map((label, index) => (
                          <li key={index}>
                            <div className="checkbox-main">
                              <input
                                type="checkbox"
                                value={label}
                                className="checkdata"
                                checked={selectedLabels.includes(label)}
                                onChange={() => handleCheckboxChange(label)}
                              />
                              <label className=" name-lables">{label}</label>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div className="form-group col-md-6">
                      <label className="name-lables">
                        When are you planning to launch
                      </label>
                      <select
                        id="inputState"
                        className="form-control text-fiealds"
                        value={launchperiod}
                        onChange={handleLaunchpadChange}
                      >
                        <option selected />
                        <option value="0">Below 1 Month</option>
                        <option value="1">Below 3 Month's</option>
                        <option value="2">Below 6 Month's</option>
                        <option value="3">Below 1 Year</option>
                      </select>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-12">
                      <div>
                        <label className="name-lables">Message</label>
                        <textarea
                          className="form-control"
                          id="exampleFormControlTextarea1"
                          rows={3}
                          defaultValue={""}
                          name="Message"
                          onChange={handleChange}
                        />{" "}
                        {MessageErr ? (
                          <p className="text-danger">{MessageErr}</p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="btndata-contact">
                    {" "}
                    <button
                      type="submit"
                      className="btn btn-primary contactus-btn"
                    >
                      Contact Us
                    </button>
                  </div>
                </form>
              </div>
            </div>
            {/* <div className="testimonials-content">
              <div className="container">
                <div className="testimonials-top">
                  <p className="testimonials-title">Testimonials</p>
                  <p className="testimonials-subtitle">
                    Hear what our satisfied clients are saying.
                  </p>
                </div>
                <div className="row justify-content-center">
                  <div className="col-md-10">
                    <Swiper
                      direction={"horizontal"}
                      centeredSlides={false}
                      navigation={true}
                      autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                      }}
                      breakpoints={{
                        640: {
                          slidesPerView: 1,
                          // spaceBetween: 20,
                        },
                        768: {
                          slidesPerView: 2,
                          spaceBetween: 40,
                        },
                        1024: {
                          slidesPerView: 3,
                          spaceBetween: 50,
                        },
                      }}
                      modules={[Autoplay, Navigation]}
                      className="mySwiper"
                      grabCursor={true}
                      freeMode={true}
                      spaceBetween={50}
                      // slidesPerView={6}
                      scrollbar={{ draggable: true }}
                    >
                      <SwiperSlide>
                        <div className="testimonials-carousel">
                          <img
                            className="testimonials-image"
                            src="./images/landing/testi-image-1.png"
                            alt="no-data"
                          />
                          <p className="testimonials-text">
                            We and our clients love everything about our service
                            and thanks to Diffco we have super cool website for
                            our clients and easy to use order/client management
                            admin part for us.
                          </p>
                          <div className="media">
                            <img
                              src="./images/landing/profile.png"
                              className="profile-image mr-2"
                              alt="no-data"
                            />
                            <div className="media-body">
                              <h5 className="mt-0 profile-title">
                                Ruslana Golunova
                              </h5>
                              <p className="profile-subtitle">
                                Co-founder, Starmetaverse
                              </p>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="testimonials-carousel">
                          <img
                            className="testimonials-image testimonials-image2"
                            src="./images/landing/testi-image-2.png"
                            alt="no-data"
                          />
                          <p className="testimonials-text">
                            We and our clients love everything about our service
                            and thanks to Diffco we have super cool website for
                            our clients and easy to use order/client management
                            admin part for us.
                          </p>
                          <div className="media">
                            <img
                              src="./images/landing/profile.png"
                              className="profile-image mr-2"
                              alt="no-data"
                            />
                            <div className="media-body">
                              <h5 className="mt-0 profile-title">
                                Ruslana Golunova
                              </h5>
                              <p className="profile-subtitle">
                                Co-founder, Starmetaverse
                              </p>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        {" "}
                        <div className="testimonials-carousel">
                          <img
                            className="testimonials-image testimonials-image2"
                            src="./images/landing/testi-image-3.png"
                            alt="no-data"
                          />
                          <p className="testimonials-text">
                            We and our clients love everything about our service
                            and thanks to Diffco we have super cool website for
                            our clients and easy to use order/client management
                            admin part for us.
                          </p>
                          <div className="media">
                            <img
                              src="./images/landing/profile.png"
                              className="profile-image mr-2"
                              alt="no-data"
                            />
                            <div className="media-body">
                              <h5 className="mt-0 profile-title">
                                Ruslana Golunova
                              </h5>
                              <p className="profile-subtitle">
                                Co-founder, Starmetaverse
                              </p>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        {" "}
                        <div className="testimonials-carousel">
                          <img
                            className="testimonials-image"
                            src="./images/landing/testi-image-1.png"
                            alt="no-data"
                          />
                          <p className="testimonials-text">
                            We and our clients love everything about our service
                            and thanks to Diffco we have super cool website for
                            our clients and easy to use order/client management
                            admin part for us.
                          </p>
                          <div className="media">
                            <img
                              src="./images/landing/profile.png"
                              className="profile-image mr-2"
                              alt="no-data"
                            />
                            <div className="media-body">
                              <h5 className="mt-0 profile-title">
                                Ruslana Golunova
                              </h5>
                              <p className="profile-subtitle">
                                Co-founder, Starmetaverse
                              </p>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        {" "}
                        <div className="testimonials-carousel">
                          <img
                            className="testimonials-image testimonials-image2"
                            src="./images/landing/testi-image-2.png"
                            alt="no-data"
                          />
                          <p className="testimonials-text">
                            We and our clients love everything about our service
                            and thanks to Diffco we have super cool website for
                            our clients and easy to use order/client management
                            admin part for us.
                          </p>
                          <div className="media">
                            <img
                              src="./images/landing/profile.png"
                              className="profile-image mr-2"
                              alt="no-data"
                            />
                            <div className="media-body">
                              <h5 className="mt-0 profile-title">
                                Ruslana Golunova
                              </h5>
                              <p className="profile-subtitle">
                                Co-founder, Starmetaverse
                              </p>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        {" "}
                        <div className="testimonials-carousel">
                          <img
                            className="testimonials-image testimonials-image2"
                            src="./images/landing/testi-image-3.png"
                            alt="no-data"
                          />
                          <p className="testimonials-text">
                            We and our clients love everything about our service
                            and thanks to Diffco we have super cool website for
                            our clients and easy to use order/client management
                            admin part for us.
                          </p>
                          <div className="media">
                            <img
                              src="./images/landing/profile.png"
                              className="profile-image mr-2"
                              alt="no-data"
                            />
                            <div className="media-body">
                              <h5 className="mt-0 profile-title">
                                Ruslana Golunova
                              </h5>
                              <p className="profile-subtitle">
                                Co-founder, Starmetaverse
                              </p>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                    </Swiper>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="our-offices">
              <div className="container">
                <div className="row">
                  <div className="col-md-6">
                    <div className="ouroffice-left">
                      <h3 className="office-title">Our Offices</h3>
                      <div className="media address-data">
                        <img
                          src="./images/contactus/office-image.png"
                          className="align-self-center address-image mr-3"
                          alt="no-data"
                        />
                        <div className="media-body mt-auto mb-auto">
                          <h5 className="address-title mt-0">India Office</h5>
                          <p className="addrerss-para">
                            Gowri Arcade, 3rd Floor, HIG-A-85, Jayabheri
                            Enclave,
                          </p>
                          <p className="addrerss-para addrerss-para1">
                            Gachibowli, Hyderabad, Telangana 500032.
                          </p>
                        </div>
                      </div>
                      <div className="media address-data">
                        <img
                          src="./images/contactus/office-image.png"
                          className="align-self-center address-image mr-3"
                          alt="no-data"
                        />
                        <div className="media-body mt-auto mb-auto">
                          <h5 className="address-title mt-0">UAE Office</h5>
                          <p className="addrerss-para">
                            Tamani Arts - 1519 - Business Bay
                          </p>
                          <p className="addrerss-para addrerss-para1">
                            Dubai - United Arab Emirates
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                  
                    <Lottie
                      className="map-image"
                      animationData={map}
                      loop={true}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="start-making-content">
          <div className="container">
            <div className="row">
              <div className="col-md-10">
                <div className="making-content">
                  {/* <p class="getstart">Get Started</p> */}
                  <h3 className="making-title">Why Choose CST 3.0?</h3>
                  <p className="making-para">
                    . Produce high quality in quick turnarounds
                  </p>
                  <p className="making-para">
                    . Experience in building high-scalable products
                  </p>
                  <p className="making-para">
                    . Friendly support that you can always rely on
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="subscribe-content">
          <div className="container">
            <div className="inside-subscribe">
              <h3 className="subscrib-title">Subscribe to our newsletter</h3>
              <p className="subscrib-para">
                Stay up-to-date with the latest technology trends and
                innovations! Subscribe now for exclusive insights and updates
                from our experts!
              </p>
            </div>
            <form className="subscribe-form">
              <div className="row justify-content-center">
                <div className=" col-md-6">
                  <div className>
                    <input
                      type="email"
                      className="form-control email-data"
                      id="email"
                      placeholder="Your email address"
                    />
                  </div>
                </div>
                <div className="col-md-2">
                  <button
                    type="submit"
                    className="btn btn-primary subscribe-btn"
                  >
                    Subscribe
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default Contactus;
