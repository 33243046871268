import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

const Mobileapp = () => {
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  });
  return (
    <>
      <Helmet>
        <title>Mobile Apps</title>
      </Helmet>
      <div className="navigationssections-all">
        <div className="topblur-image">
          <img
            className="righttop-blur"
            src="/images/right-top.png"
            alt="no-data"
          />
        </div>
        <div className="leftblur-image">
          <img className="left-blur" src="/images/left.png" alt="no-data" />
        </div>
        <div className="rightblur-image">
          <img className="right-blur" src="/images/right.png" alt="no-data" />
        </div>
        {/* Native Payment Solutions */}
        <div className="NativePaymentSolutions">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-12">
                <h1 className="mainhead">Mobile Apps</h1>
                <p className="mainsubtxt">
                  We have built 100+ apps for Fortune 500 companies and Startups
                  at a fixed cost.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="NativePaymentsection4">
          <div className="container">
            <h2 className="nativepayment4head">What are our services?</h2>
            <div className="row justify-content-center">
              <div className="col-md-4">
                <div className="nativepayment4box nativepayment4box13">
                  <h6 className="nativepayment4boxtxt">
                    End-to-End Deployment Automatio
                  </h6>
                  <p className="nativepayment4boxtxt2">
                    We were one of the first few companies that launched an
                    iPhone app on the App Store in 2007. Our app developers use
                    Objective C programming language and Apple Native SDK
                    experience to develop applications for different iOS
                    devices, including iPhones, iPads and Apple Watches.
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="nativepayment4box nativepayment4box13">
                  <h6 className="nativepayment4boxtxt">
                    Android App Development
                  </h6>
                  <p className="nativepayment4boxtxt2">
                    Our Android app developers use Native Android to program
                    Android apps for Android Phones and Tablets. We build
                    Android apps that support all the latest Android versions
                    released before the launch of an app to the Play Store. If
                    any new Android version is released after the app launch,
                    upgrade to that version is handled under the maintenance
                    plan.
                  </p>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-md-4">
                <div className="nativepayment4box nativepayment4box6">
                  <h6 className="nativepayment4boxtxt">
                    Cross-Platform Development
                  </h6>
                  <p className="nativepayment4boxtxt2">
                    We also develop cross-platform applications using React
                    Native and Xamarin that can adapt to your time constraints
                    and budget. Our team can build a single app that performs
                    across both Android and iOS platforms and is compatible with
                    various devices.
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="nativepayment4box nativepayment4box6">
                  <h6 className="nativepayment4boxtxt">Web App Development</h6>
                  <p className="nativepayment4boxtxt2">
                    According to recent research, around 58% of the website
                    traffic was from mobile devices, representing the
                    significance of a mobile platform. We use technologies and
                    languages like Java, Ruby on Rails and Node.js to build
                    custom web apps that provide cross-browser support and run
                    seamlessly on mobile devices.
                  </p>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-md-4">
                <div className="nativepayment4box nativepayment4box8">
                  <h6 className="nativepayment4boxtxt">
                    Mobile Responsive Web Apps
                  </h6>
                  <p className="nativepayment4boxtxt2">
                    We utilize the latest mobile technologies to develop
                    customer-centric mobile-responsive apps that are robust and
                    scalable. Our aim is to provide an engaging user experience
                    to clients and their customers.
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="nativepayment4box nativepayment4box8">
                  <h6 className="nativepayment4boxtxt">AI-based Web Apps</h6>
                  <p className="nativepayment4boxtxt2">
                    We develop AI-based web apps to build recommendation
                    engines, implement image recognition and enable intelligent
                    automation.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="NativePaymentsection4">
          <div className="container">
            <h2 className="nativepayment4head nativepayment4head2">
              Our Development Process
            </h2>
            <div className="row justify-content-center">
              <div className="col-md-3">
                <div className="nativepayment4box nativepayment4box9">
                  <img
                    className="devprocess"
                    src="/images/innerpages/WebApplicationDevelopment/profile-2user.png"
                    alt="no-data"
                  />
                  <h6 className="nativepayment4boxtxt nativepayment4boxtxt3">
                    Planning and consulting
                  </h6>
                </div>
              </div>
              <div className="col-md-3">
                <div className="nativepayment4box nativepayment4box9">
                  <img
                    className="devprocess"
                    src="/images/innerpages/WebApplicationDevelopment/designtools.png"
                    alt="no-data"
                  />
                  <h6 className="nativepayment4boxtxt nativepayment4boxtxt3">
                    Visual and Technical Design
                  </h6>
                </div>
              </div>
              <div className="col-md-3">
                <div className="nativepayment4box nativepayment4box9">
                  <img
                    className="devprocess"
                    src="/images/innerpages/WebApplicationDevelopment/code2.png"
                    alt="no-data"
                  />
                  <h6 className="nativepayment4boxtxt nativepayment4boxtxt3">
                    Development &amp; Testing
                  </h6>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-md-3">
                <div className="nativepayment4box nativepayment4box9">
                  <img
                    className="devprocess"
                    src="/images/innerpages/WebApplicationDevelopment/code.png"
                    alt="no-data"
                  />
                  <h6 className="nativepayment4boxtxt nativepayment4boxtxt3">
                    Deployment
                  </h6>
                </div>
              </div>
              <div className="col-md-3">
                <div className="nativepayment4box nativepayment4box9">
                  <img
                    className="devprocess"
                    src="/images/innerpages/WebApplicationDevelopment/chart-success.png"
                    alt="no-data"
                  />
                  <h6 className="nativepayment4boxtxt nativepayment4boxtxt3">
                    Upgrades
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="Web3GameDevelopment">
          <div className="container">
            <div className="row">
              <div className="col-md-10">
                <div className="Web3GameDevelopment-sec">
                  <p className="Web3GameDevelopmentsub">Get Started</p>
                  <h2 className="Web3GameDevelopmentHead">
                    Start Making Your Web3 Ideas A Reality
                  </h2>
                  <p className="Web3GameDevelopmentxt">
                    Create futuristic, next-gen products with CST 3.0 and
                    confidently bring your ideas to life with our experienced
                    development team. Avail our super-friendly support to help
                    you manage and scale any Web2/Web3 products seamlessly.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="Subscribenewsletter-section">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-10">
                <h2 className="SubscribenewsletterHead">
                  Subscribe to our newsletter
                </h2>
                <p className="Subscribenewslettertxt">
                  Create futuristic, next-gen products with CST 3.0 and Stay
                  up-to-date with the latest technology trends and innovations!
                  Subscribe now for exclusive insights and updates from our
                  experts!
                </p>
                <form className="subscribe-form">
                  <div className="row justify-content-center">
                    <div className=" col-md-6">
                      <div className>
                        <input
                          type="email"
                          className="form-control email-data"
                          id="email"
                          placeholder="Your email address"
                        />
                      </div>
                    </div>
                    <div className="col-md-2">
                      <button
                        type="submit"
                        className="btn btn-primary subscribe-btn"
                      >
                        Subscribe
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default Mobileapp;
