import React, { useRef } from "react";
import { NavLink } from "react-router-dom";

const Footer = () => {
  const sectionRef = useRef(null);

  const scrollToSection = () => {
    if (sectionRef.current) {
      sectionRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  return (
    <>
      <div className="footer-main">
        <div className="topblur-image">
          <img
            className="righttop-blur"
            src="/images/right-top.png"
            alt="no-data"
          />
        </div>
        <div className="footermain-section">
          <div className="container">
            <div className="row">
              <div className="col-md">
                <div className="footer-sec">
                  <img
                    className="footer-im"
                    src="/images/navbar/logo.png"
                    alt="Chakravuyha-Logo"
                  />
                  <p className="footer-text">
                    3rd floor, Gowri Arcade, HIG-A-85, Jayabheri Enclave,
                    Gachibowli, Hyderabad, Telangana 500032, India,
                  </p>
                  <NavLink
                    className="footer-text"
                    to="mailto:info@chakravuyha.com"
                  >
                    info@chakravuyha,com
                  </NavLink>
                </div>
              </div>
              <div className="col-md">
                <div className="footer-sec">
                  <h6 className="footer-head">Services</h6>
                  <ul className="footer-lin">
                    <li className="footerte">
                      <NavLink
                        className="footer-link-txt"
                        to={"/services/web3-services"}
                      >
                        Web3 Solutions
                      </NavLink>
                    </li>
                    <li className="footerte">
                      <NavLink
                        className="footer-link-txt"
                        to={"/services/web2-services"}
                      >
                        Web2 Solutions
                      </NavLink>
                    </li>
                    <li className="footerte">
                      <NavLink
                        className="footer-link-txt"
                        to={"/services/hybrid-solutions"}
                      >
                        Hybrid Systems
                      </NavLink>
                    </li>
                    <li className="footerte">
                      <NavLink
                        className="footer-link-txt"
                        to={"/services/web2-services/mobile-app-developement"}
                      >
                        Mobile Apps
                      </NavLink>
                    </li>
                    {/* <li className="footerte">
                      <NavLink className="footer-link-txt" href>
                        IT Development
                      </NavLink>
                    </li> */}
                    <li className="footerte">
                      <NavLink
                        className="footer-link-txt"
                        to={"/services/no-code-products"}
                      >
                        NoCode Products
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
              {/* <div className="col-md">
                <div className="footer-sec">
                  <h6 className="footer-head">Developers</h6>
                  <ul className="footer-lin">
                    <li className="footerte">
                      <NavLink className="footer-link-txt" href>
                        Documentation
                      </NavLink>
                    </li>
                    <li className="footerte">
                      <NavLink className="footer-link-txt" href>
                        Privacy Policy
                      </NavLink>
                    </li>
                    <li className="footerte">
                      <NavLink className="footer-link-txt" href>
                        Cookie Policy
                      </NavLink>
                    </li>
                    <li className="footerte">
                      <NavLink className="footer-link-txt" href>
                        Git Hub
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div> */}
              <div className="col-md">
                <div className="footer-sec">
                  <h6 className="footer-head">Company</h6>
                  <ul className="footer-lin">
                    <li className="footerte">
                      <NavLink className="footer-link-txt" to={"/about-us"}>
                        About Us
                      </NavLink>
                    </li>
                    <li className="footerte">
                      <NavLink
                        className="footer-link-txt"
                        onClick={scrollToSection}
                      >
                        Our Team
                      </NavLink>
                    </li>
                    {/* <li className="footerte">
                      <NavLink className="footer-link-txt" href>
                        Expertise
                      </NavLink>
                    </li>
                    <li className="footerte">
                      <NavLink className="footer-link-txt" href>
                        Value Proposition
                      </NavLink>
                    </li> */}
                    <li className="footerte">
                      <NavLink className="footer-link-txt" href>
                        Testimonials
                      </NavLink>
                    </li>
                    <li className="footerte">
                      <NavLink className="footer-link-txt" href>
                        Press
                      </NavLink>
                    </li>
                    <li className="footerte">
                      <NavLink className="footer-link-txt" href>
                        Careers
                      </NavLink>
                    </li>
                    {/* <li className="footerte">
                      <NavLink className="footer-link-txt" href>
                        Newsletter
                      </NavLink>
                    </li> */}
                    <li className="footerte">
                      <NavLink className="footer-link-txt" to={"/blogs"}>
                        Blog
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md">
                <div className="footer-sec">
                  <h6 className="footer-head">Contact</h6>
                  <ul className="footer-lin">
                    {/* <li className="footerte">
                      <NavLink className="footer-link-txt" to={"/"}>
                        Headquarters
                      </NavLink>
                    </li> */}
                    <li className="footerte">
                      <NavLink className="footer-link-txt" to={"/contact-us"}>
                        Contact Us
                      </NavLink>
                    </li>

                    <li className="footerte">
                      <NavLink className="footer-link-txt" href>
                        Our Offices
                      </NavLink>
                    </li>

                    <li className="footerte">
                      <NavLink className="footer-link-txt" href>
                        Support
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row mt-4 mb-4">
              <div className="col-md-4">
                <p className="rightsres">
                  © 2023 Chakravuyha. All rights reserved.
                </p>
              </div>
              <div className="col-md-4">
                <ul className="Privacyterms">
                  <li>
                    <NavLink
                      className="Privacyterms-txt"
                      to={"/files/privacy-policy.pdf"}
                      target="_blank"
                    >
                      Privacy Policy
                    </NavLink>
                  </li>
                  {/* <li>
                    <NavLink
                      className="Privacyterms-txt Privacyterms-txt2"
                      href
                    >
                      Terms of use
                    </NavLink>
                  </li> */}
                </ul>
              </div>
              <div className="col-md-4">
                <ul className="Privacyterms2">
                  <li className="privacygap">
                    <NavLink
                      to={"https://www.linkedin.com/company/chakravuyha/"}
                      target="_blank"
                    >
                      <img
                        src="/images/footer/linkedin.png"
                        alt="chakravuyha software technologies linkedin page"
                      />
                    </NavLink>
                  </li>
                  <li className="privacygap">
                    <NavLink
                      to={"https://twitter.com/chakravuyha"}
                      target="_blank"
                    >
                      <img
                        src="/images/footer/twitter.png"
                        alt="chakravuyha software technologies twitter social media profile"
                      />
                    </NavLink>
                  </li>
                  <li className="privacygap">
                    <NavLink
                      to={"https://www.facebook.com/chakravuyha"}
                      target="_blank"
                    >
                      <img src="/images/footer/facebook.png" alt="facebook" />
                    </NavLink>
                  </li>
                  <li className="privacygap">
                    <NavLink
                      to={"https://instagram.com/chakravuyha.official"}
                      target="_blank"
                    >
                      <img src="/images/footer/insta.png" alt="instagram" />
                    </NavLink>
                  </li>

                  <li className="privacygap">
                    <NavLink
                      to={"https://medium.com/@chakravuyha"}
                      target="_blank"
                    >
                      <img src="/images/footer/medium.png" alt="medium" />
                    </NavLink>
                  </li>
                  <li className="privacygap">
                    <NavLink
                      to={"https://www.youtube.com/@chakravuyha"}
                      target="_blank"
                    >
                      <img src="/images/footer/youtube.png" alt="youtube" />
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
            <p className="Createdwith">
              Created with{" "}
              <span>
                <img
                  className="heart-image"
                  src="/images/footer/heart.gif"
                  alt="no-data"
                />{" "}
              </span>
              by Chakravuyha Team
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
