import "./App.css";
import Login from "./Pages/Login/Login";
import Layout from "./Pages/Layout/Layout";
import Landing from "./Pages/Landing/Landing";
import { Route, Routes } from "react-router-dom";
import Aboutus from "./Pages/Aboutus/Aboutus";
import Contactus from "./Pages/Contactus/Contactus";
import Web3 from "./Pages/Web3/Web3";
import Web2 from "./Pages/Web2/Web2";
import Hybrid from "./Pages/Hybrid/Hybrid";
import Nocode from "./Pages/Nocode/Nocode";

import Nativepayments from "./Pages/Web3pages/Nativepayments/Nativepayments";
import Metaverse from "./Pages/Web3pages/Metaverse/metaverse";
import NFTdevelopement from "./Pages/Web3pages/NFTdevelopement/NFTdevelopement";
import Web3gaming from "./Pages/Web3pages/Web3gaming/Web3gaming";
import Blockchainsolutions from "./Pages/Web3pages/Blockchainsolutions/Blockchainsolutions";

import Webapplications from "./Pages/Web2pages/Webapplications/Webapplications";
import Devops from "./Pages/Web2pages/Devops/Devops";
import Fullstack from "./Pages/Web2pages/Fullstack/Fullstack";
import Mobileapp from "./Pages/Web2pages/Mobileapp/Mobileapp";
import Web3Layout from "./Pages/Web3/Web3Layout";
import Web2layout from "./Pages/Web2/Web2layout";
import Blogsall from "./Pages/Blogs/Blogsall/Blogsall";
import Blog from "./Pages/Blogs/Blog/Blog";


function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Landing />} />
          <Route path="/cst-home" element={<Landing />} />
          <Route path="/about-us" element={<Aboutus />} />
          <Route path="/contact-us" element={<Contactus />} />
          <Route path="/blogs" element={<Blogsall />} />
          <Route path="/blog/:id" element={<Blog />} />
          {/* <Route path="/cookie-settings" element={<Cookiesettings />} />
          <Route path="/cookiepolicy" element={<Cookiespolicy />} /> */}

          <Route path="/services">
            {/*      web 3 inner pages */}
            <Route path="web3-services" element={<Web3Layout />}>
              <Route index element={<Web3 />} />
              <Route
                path="native-payments-solutions"
                element={<Nativepayments />}
              />
              <Route path="metaverse-products" element={<Metaverse />} />
              <Route path="NFT-developement" element={<NFTdevelopement />} />
              <Route path="web3-gaming" element={<Web3gaming />} />
              <Route
                path="Blockchain-solutions"
                element={<Blockchainsolutions />}
              />
            </Route>
            {/*      web 2 inner pages */}
            <Route path="web2-services" element={<Web2layout />}>
              <Route index element={<Web2 />} />
              <Route
                path="web-applicaion-developement"
                element={<Webapplications />}
              />
              <Route path="devops" element={<Devops />} />
              <Route path="fullstack" element={<Fullstack />} />
              <Route path="mobile-app-developement" element={<Mobileapp />} />
            </Route>
            <Route path="hybrid-solutions" element={<Hybrid />} />
            <Route path="no-code-products" element={<Nocode />} />
          </Route>
          {/* <Route path="/web2" element={<Web2 />} /> */}
          {/* <Route path="/nativepaymentssolutions" element={<Nativepayments />} /> */}
        </Route>
        <Route path="/login" element={<Login />} />
      </Routes>
    </>
  );
}

export default App;
