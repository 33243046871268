import React, { useEffect, useState } from "react";
import Axios from "axios";
import { Helmet } from "react-helmet";
import { Link, Navigate, useParams } from "react-router-dom";
// import { NavLink } from "react-router-dom";
import { format } from "date-fns";
const Blogsall = () => {
  // const [blogInside, setblogInside] = useState();
  const [allBlogs, setallBlogs] = useState([]);
  const [popularBlogs, setpopularBlogs] = useState([]);
  const [categorylist, setcategorylist] = useState([]);
  const { id } = useParams();
  const [blogdata, setblogdata] = useState("");
  function formatDate(dateString) {
    const date = new Date(dateString);
    return format(date, "yyyy-MM-dd");
  }
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  });
  useEffect(() => {
    blogs();
    getCategory();
  }, []);
  function handleCategoryClick(selectedObject) {
    let allData = [];
    const body = {
      filterId: selectedObject.categories,
    };
    const url = "https://blogapi.chakravuyha.com/api/blog/filterById";

    const headerTypes = {
      "content-type": "application/json",
    };
    Axios.post(url, body, { headers: headerTypes }).then((res) => {
      let allData = [];
      allData.push(res.data);
      setallBlogs(res.data);
    });
  }

  const userData = (id) => {
    Navigate(`/blog/${id}`, { replace: true });
  };
  const blogs = (e) => {
    // e.preventDefault();

    Axios.get("https://blogapi.chakravuyha.com/api/blog/allBlogs")
      .then((res) => {
        let allData = [];
        allData.push(res.data);
        setallBlogs(res.data);
        setpopularBlogs(res.data);
      })
      .catch((err) => {});
  };
  const getCategory = (e) => {
    Axios.get("https://blogapi.chakravuyha.com/api/blog/getCategories")
      .then((res) => {
        setcategorylist(res.data);
      })
      .catch((err) => {
        console.error("Error fetching categories:", err);
      });
  };

  return (
    <>
      <Helmet>
        <title>Blog</title>
      </Helmet>
      <div className="get_Strted-page">
        <div className="blog-dBelow">
          <div className="blogs-topinside">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-9">
                  <div className="inside-aboutus">
                    <h3 className="aboutus-title">Blog</h3>
                    <p className="aboutus-para">
                      It is a long established fact that a reader will be
                      distracted by the readable content of a page when looking
                      at its layout. The point of using Lorem Ipsum is that it
                      has a moreIt is a long established fact that a reader will
                      be distracted by the readable content of a page when
                      looking at its layout. The point of using Lorem Ipsum is
                      that it has a more
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-5 pe-lg-4">
                <div className="left-data">
                  <div className="search-box">
                    <h2 className="search-title">Search</h2>
                    <div className="input-group inp-search">
                      <input
                        type="text"
                        className="form-control search-inside"
                        placeholder="Search Here.."
                      />
                      <img
                        src="./images/blogs/search.png"
                        alt=""
                        className="search-icn"
                      />
                      {/* <span class=" search-butn " >
                                    <i class="fas fa-search srch-icon"></i>
                                </span> */}
                    </div>
                  </div>
                  <div className="blog-catagories">
                    <h2 className="blg-title">Blog categories</h2>
                    <select
                      className="form-select d-block d-md-none"
                      aria-label="Default select example"
                    >
                      <option className="BLG-types" onClick={blogs}>
                        All Blogs
                      </option>
                      {categorylist?.map((data, index) => {
                        return (
                          <option
                            className="BLG-types"
                            onClick={() => handleCategoryClick(data)}
                            key={index}
                          >
                            #{data.categories}
                          </option>
                        );
                      })}
                    </select>
                    <div className="d-none d-md-block">
                      <p className="blgTypes-below" onClick={blogs}>
                        All Blogs
                      </p>
                      {categorylist?.map((data, index) => {
                        return (
                          <p
                            className="blgTypes-below"
                            onClick={() => handleCategoryClick(data)}
                            key={index}
                          >
                            #{data.categories}
                          </p>
                        );
                      })}
                    </div>
                  </div>
                  <div className="post-section">
                    <h4 className="popularr-pst">Popular posts</h4>
                    {popularBlogs?.map((data, index) => {
                      const imageUrl = `data:image/jpg;base64,${data.image}`;
                      return (
                        <ul className="Post-top list-unstyled d-flex">
                          <li className="post-left">
                            <div className="Pimage-box">
                              <img
                                src={imageUrl}
                                alt=""
                                className="post-images"
                              />
                              <span className="numbers">{index + 1}</span>
                            </div>
                          </li>
                          <li className="post-right">
                            <p className="posts-content">{data.title}</p>
                            <ul className="posts-bottom list-unstyled d-flex">
                              <li className="dat-user">
                                <span className="icnss">
                                  <i className="far fa-clock" />
                                </span>
                                {formatDate(data.date)}
                              </li>
                              <li className="dat-user">
                                <span className="icnss">
                                  <i className="far fa-user" />
                                </span>
                                martha grim
                              </li>
                            </ul>
                          </li>
                        </ul>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="col-md-7 ps-lg-3">
                {allBlogs?.map((data) => {
                  const imageUrl = `data:image/jpg;base64,${data.image}`;
                  return (
                    <div className="right-Data" key={data._id}>
                      <Link className="linksdata-ins" to={`/blog/${data._id}`}>
                        <div className="cardsBelow">
                          <div className="cardImgs">
                            <div className="blogs-topimages">
                              <img
                                src={imageUrl}
                                alt="card"
                                className="edit-CardIMG"
                              />
                            </div>
                            <img
                              src="./images/blogs/blog-profile.png"
                              alt=""
                              className="user-image"
                            />
                          </div>
                          <div className="card-bdy">
                            <ul className="bdy-botom list-unstyled d-flex">
                              <li className="date-user">
                                <span className="iconss">
                                  <i className="far fa-clock" />
                                </span>
                                {formatDate(data.date)}
                              </li>
                              <li className="date-user">
                                <span className="iconss">
                                  <i className="far fa-user" />
                                </span>
                                John Halliburton
                              </li>
                            </ul>
                            <h2 className="Cbody-tiitle">{data.title}</h2>
                          </div>
                        </div>
                      </Link>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Blogsall;
