import React, { useEffect } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// import { Navigation } from "swiper";
import { Helmet } from "react-helmet";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay } from "swiper";
// import { NavLink } from "react-router-dom";

const Hybrid = () => {
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  });
  return (
    <>
      <Helmet>
        <title>Hybrid</title>
      </Helmet>
      <div className="hybrid-allcontent">
        <div className="topblur-image">
          <img
            className="righttop-blur"
            src="/images/right-top.png"
            alt="Hybrid-Solutions"
          />
        </div>
        <div className="leftblur-image">
          <img className="left-blur" src="/images/left.png" alt="no-data" />
        </div>
        <div className="rightblur-image">
          <img className="right-blur" src="/images/right.png" alt="no-data" />
        </div>
        <div className="leftblur-image leftblur-image2">
          <img className="left-blur" src="/images/left.png" alt="no-data" />
        </div>
        <div className="rightblur-image rightblur-image2">
          <img className="right-blur" src="/images/right.png" alt="no-data" />
        </div>
        {/* ===  hybrid solutions Start  === */}
        <div className="hybrid-solutions">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="hybrid-solutions-left">
                  <img
                    className="mask1-img"
                    src="/images/hybrid/mask.png"
                    alt="nodata"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="hybrid-solutions-right">
                  <p className="hybrid-solutions-title">Hybrid Solutions</p>
                  <p className="hybrid-solutions-content">
                    The web is evolving, so should your business! Renovate your
                    Web2 company to Web3 standard with CST 3.0
                  </p>
                  <p className="hybrid-solutions-content">
                    Want an insight into potential Web3 entry gateways? Our
                    development and migration services from Web2 to Web3, is
                    just one message away.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ===  hybrid solutions End  === */}
        {/* ===  reason to migrate Start  === */}
        <div className="reasons-migrate">
          <div className="container">
            <div className="reasons-migarte-inside">
              <p className="reasons-migarte-inside-title">
                Reasons to migrate from web2 to web3
              </p>
              <div className="reason-migrate-cards">
                <div className="reason-migrate-cards-inside">
                  <p className="reason-migrate-cards-title">Decentralization</p>
                  <p className="reason-migrate-cards-content">
                    We build decentralized web3 apps that do not require any
                    central authority or identity to carry out various
                    operations.
                  </p>
                </div>
                <div className="reason-migrate-cards-inside">
                  <p className="reason-migrate-cards-title">P2P transactions</p>
                  <p className="reason-migrate-cards-content">
                    Our web3 applications are based on blockchain; thus,
                    offering peer-to-peer transactions and maintaining direct
                    communication.
                  </p>
                </div>
                <div className="reason-migrate-cards-inside">
                  <p className="reason-migrate-cards-title">Excellent uptime</p>
                  <p className="reason-migrate-cards-content">
                    We develop apps powered by Smart contracts that ensure an
                    increase in the run time of the web3 apps.
                  </p>
                </div>
              </div>
              <div className="reason-migrate-cards">
                <div className="reason-migrate-cards-inside">
                  <p className="reason-migrate-cards-title">Fault toleration</p>
                  <p className="reason-migrate-cards-content">
                    With a decentralized network, we make sure that the web3 app
                    is fully secured from hackers in case a single node is
                    working.
                  </p>
                </div>
                <div className="reason-migrate-cards-inside">
                  <p className="reason-migrate-cards-title">Privacy</p>
                  <p className="reason-migrate-cards-content">
                    Our team builds dApps that do not request users' personal
                    information. Thus, ensuring safe and secure usage.
                  </p>
                </div>
                <div className="reason-migrate-cards-inside">
                  <p className="reason-migrate-cards-title">Permissionless</p>
                  <p className="reason-migrate-cards-content">
                    Our efficiently built web3 apps are permissionless and give
                    equal access rights to everyone.
                  </p>
                </div>
              </div>
              <div className="reason-migrate-cards">
                <div className="reason-migrate-cards-inside">
                  <p className="reason-migrate-cards-title">Data security</p>
                  <p className="reason-migrate-cards-content">
                    Our blockchain-based web3 apps are highly secured and ensure
                    complete data protection without any risk of failure.
                  </p>
                </div>
                <div className="reason-migrate-cards-inside">
                  <p className="reason-migrate-cards-title">Cost savings</p>
                  <p className="reason-migrate-cards-content">
                    The decentralized apps built by our team cut down the cost
                    of the clients by eliminating the requirement of hiring
                    people.
                  </p>
                </div>
                <div className="reason-migrate-cards-inside">
                  <p className="reason-migrate-cards-title">
                    Cloud storage system
                  </p>
                  <p className="reason-migrate-cards-content">
                    Our web3 apps utilize cloud storage systems like Swarm,
                    IFPS, etc., for additional storage.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ===  reason to migrate End  === */}
        {/* ===  conversion services Start  === */}
        <div className="conversion-services">
          <div className="container">
            <div className="conversion-services-inside">
              <p className="conversion-services-title">
                Our web2 to web3 conversion services
              </p>
              <p className="conversion-services-content">
                We provide the below-listed services for converting a web2 app
                into a highly robust web3 technology.
              </p>
              <p className="conversion-services-heading">
                Wallet/Payment migration
              </p>
              <p className="conversion-services-heading-content">
                While migrating web2 to web3, migration of a simple payment
                gateway to a crypto wallet is also mandated. We mostly consider
                Metamask crypto wallet for migration as it provides an easy
                gateway to many blockchain-based decentralised apps.
              </p>
              <p className="conversion-services-head-content">
                Contact our specialists if you are looking forward to getting
                top-notch web2 to web3 conversion services. We will cater to you
                with the best possible services.
              </p>
            </div>
          </div>
        </div>
        {/* ===  conversion services End  === */}
        {/* ===  migration process start  === */}
        <div className="migration-process">
          <div className="container">
            <div className="inside-migration-process">
              <p className="migration-process-title">
                CST 3.0 Web2 To Web3 Migration Process
              </p>

              <Swiper
                direction={"horizontal"}
                centeredSlides={false}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                breakpoints={{
                  640: {
                    slidesPerView: 1,
                  },
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 40,
                  },
                  1024: {
                    slidesPerView: 3,
                    spaceBetween: 50,
                  },
                }}
                modules={[Autoplay]}
                className="mySwiper"
                grabCursor={true}
                freeMode={true}
                spaceBetween={50}
                // slidesPerView={6}
                scrollbar={{ draggable: true }}
                dir="rtl"
              >
                <SwiperSlide>
                  <div className="analysis-planning">
                    <p className="analysis-planning-title">
                      Analysis and Planning
                    </p>
                    <p className="analysis-planning-content">
                      We begin the conversion process by discussing the project
                      requirements with you. We analyze the existing
                      infrastructure and factors at which the web2 app is
                      lacking and plan the migration accordingly.
                    </p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="analysis-planning">
                    <p className="analysis-planning-title">
                      Evaluation of the project
                    </p>
                    <p className="analysis-planning-content">
                      During this phase, we evaluate every aspect of the
                      project, including the budget, security, and compliant
                      infrastructure, with clear policy rules for the successful
                      delivery of the project.
                    </p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="analysis-planning">
                    <p className="analysis-planning-title">
                      Installation and migration
                    </p>
                    <p className="analysis-planning-content">
                      At this phase of the conversion process, our experts
                      install the mandatory software required for web2 to web3
                      migration and successfully carry out the migration
                      process.
                    </p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="analysis-planning">
                    <p className="analysis-planning-title">
                      Testing and deployment
                    </p>
                    <p className="analysis-planning-content">
                      When the app is prepared on web3, it undergoes rigorous
                      testing procedures to ensure that it is free from all bugs
                      and errors. Upon resolution of all the faults, the web3
                      app is ready to be deployed.
                    </p>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
        {/* ===  migration process end  === */}
        {/* ===  exclusive features start  === */}
        <div className="exclusive-features">
          <div className="container">
            <div className="exclusive-features-inside">
              <p className="exclusive-features-title">
                Exclusive Features Of Web3
              </p>
            </div>
            <div className="exclusive-features-inside-cards">
              <div className="artificial-intilligence">
                <p className="artificial-intilligence-title">
                  Artificial Intelligence
                </p>
                <p className="artificial-intilligence-content">
                  We convert web2 apps into web3 with an in-built feature of
                  Artificial Intelligence, which means it can easily blend the
                  dApp with natural language processing (NLP). It results in
                  quick outcomes.
                </p>
              </div>
              <div className="artificial-intilligence">
                <p className="artificial-intilligence-title">Semantic Web</p>
                <p className="artificial-intilligence-content">
                  Web3 is also considered as the Semantic Web. Through our
                  semantic web services, we ensure the creation of data stores
                  on the web, build vocabularies, and efficiently handle data.
                </p>
              </div>
            </div>
            <div className="exclusive-features-inside-cards">
              <div className="artificial-intilligence">
                <p className="artificial-intilligence-title">Ubiquity</p>
                <p className="artificial-intilligence-content">
                  Our web3 apps are IoT based that connect the internet to every
                  individual at any time. Multiple apps, the web, and devices
                  are connected for uninterrupted access anywhere.
                </p>
              </div>
              <div className="artificial-intilligence">
                <p className="artificial-intilligence-title">3D Graphics</p>
                <p className="artificial-intilligence-content">
                  The compelling 3D graphics used by our experts make the web3
                  app more appealing to your customer base. We use the latest
                  tech stack to impart top-notch effects in the web3 apps.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* ===  exclusive features end  === */}
        {/* ===  connect with vuyha start  === */}
        <div className="connect-with-vuyha">
          <div className="container">
            <div className="connect-with-vuyha-inside">
              <p className="connect-with-vuyha-title">
                Connect with CST 3.0 Solutions For converting web2 to web3
              </p>
              <p className="connect-with-vuyha-content">
                If you are eager to start with the conversion of your business
                from web2 to web3, then feel free to connect with us. We have
                over 6 years of experience in Metaverse, Web3, and Blockchain
                services.
              </p>
              <p className="connect-with-vuyha-content">
                Our expertise in the latest and emerging technologies has
                curated many businesses in the web3. We offer migration services
                fro web2 to web3 for aspiring business owners. We have a huge
                client base and some emerging{" "}
                <span className="connect-with-fortune">
                  Fortune 500 companies.
                </span>
                We deliver{" "}
                <span className="connect-with-fortune">
                  100% guaranteed and satisfactory results.
                </span>
              </p>
            </div>
          </div>
        </div>
        {/* ===  connect with vuyha end  === */}
        {/* ===  makes you choose start  === */}
        <div className="makes-you-choose">
          <div className="container">
            <div className="makes-you-choose-inside">
              <p className="makes-you-choose-title">
                What makes you choose us?
              </p>
            </div>
            <div className="makes-you-choose-inside-cards">
              <div className="latest-technology">
                <p className="latest-technology-title">
                  Latest technology stack
                </p>
                <p className="latest-technology-content">
                  We are a proud team of developers that uses the latest
                  technology, such as blockchain, AR/VR, IoT, AI/ML, etc., to
                  build customized projects and convert applications.
                </p>
              </div>
              <div className="latest-technology">
                <p className="latest-technology-title">
                  Highly scalable platform
                </p>
                <p className="latest-technology-content">
                  Utilizing emerging technologies, we thrive on building highly
                  scalable and robust applications based on Web3 technology.
                </p>
              </div>
              <div className="latest-technology">
                <p className="latest-technology-title">Advanced security</p>
                <p className="latest-technology-content">
                  We maintain the application's security level with advanced
                  security measures and complete data protection.
                </p>
              </div>
            </div>
            <div className="support-authenticity-inside-cards">
              <div className="support-authenticity">
                <p className="support-authenticity-title">24/7 support</p>
                <p className="support-authenticity-content">
                  We provide round-the-clock support services to our clients. We
                  are just a call away at the time of need.
                </p>
              </div>
              <div className="support-authenticity">
                <p className="support-authenticity-title">Authenticity</p>
                <p className="support-authenticity-content">
                  You can cross-check our authenticity through ratings over
                  Clutch, Goodfirms, etc. Why wait anymore when we are ready for
                  your project deployment on web3? Talk to our experts now!
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* ===  makes you choose end  === */}
        {/* ===  migration process start  === */}
        <div className="migration-process">
          <div className="container">
            <div className="inside-migration-process">
              <p className="migration-process-title">
                In What Niche Do You Want Migration Services?
              </p>

              <Swiper
                direction={"horizontal"}
                centeredSlides={false}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                breakpoints={{
                  640: {
                    slidesPerView: 1,
                  },
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 40,
                  },
                  1024: {
                    slidesPerView: 3,
                    spaceBetween: 50,
                  },
                }}
                modules={[Autoplay]}
                className="mySwiper"
                grabCursor={true}
                freeMode={true}
                spaceBetween={50}
                // slidesPerView={6}
                scrollbar={{ draggable: true }}
                dir="rtl"
              >
                <SwiperSlide>
                  <div className="analysis-planning">
                    <p className="analysis-planning-title">Social media</p>
                    <p className="analysis-planning-content">
                      If you are having a web2-based social media platform and
                      want to transform it into web3, then we can solve the
                      purpose for you. We build decentralised social media
                      platforms such as Facebook, Twitter, Youtube, etc., that
                      solve all the privacy problems you might encounter in your
                      web2 apps.
                    </p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <div className="analysis-planning">
                    <p className="analysis-planning-title">Mobile games</p>
                    <p className="analysis-planning-content">
                      Peer-to-peer gaming is a huge trend in blockchain
                      technology. We're not only talking about internet casinos
                      here but about every game. For example, we can build
                      various web3-based games, like CryptoKitties, to provide a
                      more immersive gaming experience.
                    </p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="analysis-planning">
                    <p className="analysis-planning-title">
                      Financial applications
                    </p>
                    <p className="analysis-planning-content">
                      We are also experts at developing decentralised finance
                      apps based on blockchain technology. This niche lets
                      consumers perform financial transactions without engaging
                      traditional financial institutions like banks, credit card
                      companies, or insurance providers. Convert your web2
                      financial apps to web3 and provide your users an extra
                      degree of security.
                    </p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="analysis-planning">
                    <p className="analysis-planning-title">Streaming music</p>
                    <p className="analysis-planning-content">
                      We excel at building web3-based music streaming apps like
                      Musicoin that allow users to broadcast music, earn
                      bitcoin, and pay artists. Through our web3-based music
                      apps, it will become easy for users to sign up, post their
                      own music, and be paid by listeners.
                    </p>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
        {/* ===  migration process end  === */}
        {/* ===  table content start  === */}
        <div className="web2-web3">
          <div className="container">
            <div className="web2-web3-inside">
              <div className="table-responsive">
                <table>
                  <tbody>
                    <tr className="web2-web3-inside-content">
                      <th className="web2-web3-title" />
                      <th className="web2-web3-title">Web2</th>
                      <th className="web2-web3-title-1">Web3</th>
                    </tr>
                    <tr className="web2-web3-inside-content">
                      <td className="web2-web3-left-title">Trust model</td>
                      <td className="web2-web3-data">
                        Centralized services, servers, and software Users need
                        to trust the companies behind services, servers, and
                        software
                      </td>
                      <td className="web2-web3-data">
                        Decentralized; peer-to-peer; no central authority; no
                        single point of failure Trust is minimized – users need
                        to trust the decentralized protocol.
                      </td>
                    </tr>
                    <tr className="web2-web3-inside-content">
                      <td className="web2-web3-left-title">Governance</td>
                      <td className="web2-web3-data">
                        Digital giants consolidate the governance power
                      </td>
                      <td className="web2-web3-data">
                        Decentralized autonomous organizations (DAOs) ensure
                        that the governance is distributed to governance token
                        holders
                      </td>
                    </tr>
                    <tr className="web2-web3-inside-content">
                      <td className="web2-web3-left-title">Business model</td>
                      <td className="web2-web3-data">
                        Digital giants and service providers own customer data
                        They use customer data to earn revenue
                      </td>
                      <td className="web2-web3-data">
                        The blockchain network pays transaction validators for
                        their work/stake (PoW/PoS) Game theory is employed to
                        maintain transaction integrity
                      </td>
                    </tr>
                    <tr className="web2-web3-inside-content">
                      <td className="web2-web3-left-title">Content</td>
                      <td className="web2-web3-data">
                        Dynamic, user-generated Source content can be duplicated
                      </td>
                      <td className="web2-web3-data">
                        Users own the content (uncoupled from Web 2.0 services)
                      </td>
                    </tr>
                    <tr className="web2-web3-inside-content">
                      <td className="web2-web3-left-title">
                        User participation models
                      </td>
                      <td className="web2-web3-data">
                        Free services in exchange for user data Payments to
                        intermediaries for running services and software
                      </td>
                      <td className="web2-web3-data">
                        Users own their data and content and can monetize it
                        Payments directly to blockchain transaction validators
                      </td>
                    </tr>
                    <tr className="web2-web3-inside-content">
                      <td className="web2-web3-left-title">
                        User interface (UI)
                      </td>
                      <td className="web2-web3-data">
                        Web
                        <br />
                        Social networks
                        <br />
                        Mobile apps
                      </td>
                      <td className="web2-web3-data">
                        Decentralized applications (dapps) Centralized
                        marketplaces or services
                      </td>
                    </tr>
                    <tr className="web2-web3-inside-content">
                      <td className="web2-web3-left-title">
                        User authentication methods
                      </td>
                      <td className="web2-web3-data">
                        User IDs <br />
                        Passwords
                        <br />
                        Other authentication
                      </td>
                      <td className="web2-web3-data">
                        Private keys that unlock access to owners’ records on a
                        blockchain Private keys can be in self-hosted wallets or
                        third-party wallets
                      </td>
                    </tr>
                    <tr className="web2-web3-inside-content">
                      <td className="web2-web3-left-title">Financial system</td>
                      <td className="web2-web3-data">
                        Banks, other financial institutions, and networks
                        centrally manage the financial system.
                      </td>
                      <td className="web2-web3-data">
                        Smart contracts (on-chain software) and blockchain
                        protocols run the financial system.
                        <br />
                        No centralized control
                        <br />
                        No intermediaries to pay
                      </td>
                    </tr>
                    <tr className="web2-web3-inside-content">
                      <td className="web2-web3-left-title">Currency</td>
                      <td className="web2-web3-data">
                        Centrally managed and government-backed
                      </td>
                      <td className="web2-web3-data">
                        Built into decentralized blockchains Users are their own
                        banks; however, they can delegate centralized exchanges.
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {/* ===  table content end  === */}
        {/* ===  subscribe newsletter start  === */}
        {/* <div className="subscribe-newsletter">
          <div className="container">
            <div className="subscribe-newsletter-inside">
              <p className="subscribe-newsletter-title">
                Subscribe to our newsletter
              </p>
              <p className="subscribe-newsletter-content">
                Stay up-to-date with the latest technology trends and
                innovations! Subscribe now for exclusive insights and updates
                from our experts!
              </p>
              <div className="row justify-content-center">
                <div className="col-md-6">
                  <input
                    type="email"
                    className="your-email-address
                                form-control"
                    placeholder="Your email address"
                  />
                </div>
                <div className="col-md-2 pl-0">
                  <NavLink className="btn btn-primary newsletter-subscribe">
                    Subscribe
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* ===  subscribe newsletter end  === */}
      </div>
    </>
  );
};

export default Hybrid;
