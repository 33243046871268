import React, { useEffect } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// import { Navigation } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Helmet } from "react-helmet";
// import required modules
import { Autoplay } from "swiper";
import { NavLink } from "react-router-dom";

const NFTdevelopement = () => {
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  });
  return (
    <>
      <Helmet>
        <title>NFT Development</title>
      </Helmet>
      <div className="navigationssections-all">
        <div className="topblur-image">
          <img
            className="righttop-blur"
            src="/images/right-top.png"
            alt="no-data"
          />
        </div>
        <div className="leftblur-image">
          <img className="left-blur" src="/images/left.png" alt="no-data" />
        </div>
        <div className="rightblur-image">
          <img className="right-blur" src="/images/right.png" alt="no-data" />
        </div>
        {/* Native Payment Solutions */}
        <div className="NativePaymentSolutions">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-12">
                <h1 className="mainhead">CST 3.0 NFT Development Services</h1>
                <p className="mainsubtxt">
                  Unleash the power of our full-stack NFT product development
                  services. We offer front-end and back-end development, plus
                  smart contract creation, under one umbrella. Our offering
                  includes support for multiple blockchain networks like
                  Ethereum, Avalanche, and Polkadot.
                </p>
                <p className="mainsubtxt">
                  Enjoy amazing features like NFT creation, storefront
                  exploration, wallet integration, bid placing, secure storage
                  solution, and more.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="NativePaymentsection4">
          <div className="container">
            <h2 className="nativepayment4head">
              Here's Everything We Can Do In The NFT World
            </h2>
            <div className="row justify-content-center">
              <div className="col-md-4">
                <div className="nativepayment4box nativepayment4box3">
                  <h6 className="nativepayment4boxtxt">
                    Build World Class NFTs
                  </h6>
                  <p className="nativepayment4boxtxt2">
                    We offer a tailored NFT development service that tokenizes
                    assets precisely to meet your needs. Our team adheres to
                    your needs by developing and issuing new SPL tokens with
                    superior functionality and use cases.
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="nativepayment4box nativepayment4box3">
                  <h6 className="nativepayment4boxtxt">
                    Design and Development NFT Marketplaces
                  </h6>
                  <p className="nativepayment4boxtxt2">
                    Our expert team designs and builds user-centric NFT
                    marketplaces, utilizing their deep knowledge of the ERC-721
                    and ERC-1155 standards, smart contracts, and IPFS protocols.
                  </p>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-md-4">
                <div className="nativepayment4box nativepayment4box3">
                  <h6 className="nativepayment4boxtxt">
                    NFT Audits &amp; Smart Contract Development
                  </h6>
                  <p className="nativepayment4boxtxt2">
                    Tap into our NFT smart contract development and audit
                    services to guarantee the error-free function of your smart
                    contracts. Enjoy a seamless, automated experience of your
                    NFT transactions with our robust development method and
                    highly credible audits.
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="nativepayment4box nativepayment4box3">
                  <h6 className="nativepayment4boxtxt">Post Launch Support</h6>
                  <p className="nativepayment4boxtxt2">
                    We provide ongoing monitoring and maintenance of third-party
                    upgrades, new OS releases and ensure your nodes are always
                    running seamlessly. Plus, you can count on us for reliable
                    customer support even after the product/platform launch.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Vyuha 3.0 Advantage */}
        <div className="VyuhaAdvantage-section">
          <h2 className="VyuhaAdvantagehead">CST 3.0 NFT Use Cases</h2>
          <div className="container">
            <Swiper
              direction={"horizontal"}
              centeredSlides={false}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              breakpoints={{
                640: {
                  slidesPerView: 1,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 40,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 50,
                },
              }}
              modules={[Autoplay]}
              className="mySwiper"
              grabCursor={true}
              freeMode={true}
              spaceBetween={50}
              // slidesPerView={6}
              scrollbar={{ draggable: true }}
            >
              <SwiperSlide>
                <div className="VyuhaAdvantageslide">
                  <h6 className="Advantageslidehead">Crypto Collectibles</h6>
                  <p className="Advantageslidesub">
                    Our NFT marketplace lets you customize non-fungible assets
                    in industries such as art, music, real estate, games, and
                    more.
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="VyuhaAdvantageslide">
                  <h6 className="Advantageslidehead">Gaming</h6>
                  <p className="Advantageslidesub">
                    With the help of NFTs, gamers can securely transfer in-game
                    assets like a rare skin or avatar and protect their
                    ownership rights.
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="VyuhaAdvantageslide">
                  <h6 className="Advantageslidehead">Art</h6>
                  <p className="Advantageslidesub">
                    Artists can now confidently trade their artwork on NFT
                    platforms without worrying about copyright protection or
                    content theft.
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="VyuhaAdvantageslide">
                  <h6 className="Advantageslidehead">Trading Marketplace</h6>
                  <p className="Advantageslidesub">
                    NFT marketplaces allow users to purchase, sell, and explore
                    various NFTs, such as digital art, domain names, and virtual
                    spaces.
                  </p>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
        {/*  */}
        <div className="NativePaymentsection4">
          <div className="container">
            <h2 className="nativepayment4head">
              Why Choose CST 3.0 For NFT Development?
            </h2>
            <div className="row justify-content-center">
              <div className="col-md-4">
                <div className="nativepayment4box nativepayment4box4">
                  <h6 className="nativepayment4boxtxt">
                    Talent &amp; Experience
                  </h6>
                  <p className="nativepayment4boxtxt2">
                    Our veteran blockchain experts comprehensively understand
                    various NFT standards and blockchain protocols, working with
                    agile and practical innovation strategies.
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="nativepayment4box nativepayment4box4">
                  <h6 className="nativepayment4boxtxt">Blockchain Expertise</h6>
                  <p className="nativepayment4boxtxt2">
                    As a qualified NFT marketplace development company, we
                    specialize in designing solutions for varying blockchain
                    platforms, including Ethereum, Stellar, EOS, and Tezos.
                  </p>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-md-4">
                <div className="nativepayment4box nativepayment4box4">
                  <h6 className="nativepayment4boxtxt">
                    Security &amp; Confidentiality
                  </h6>
                  <p className="nativepayment4boxtxt2">
                    We guarantee the discreetness of your project-related
                    information through a signed NDA.
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="nativepayment4box nativepayment4box4">
                  <h6 className="nativepayment4boxtxt">
                    Rapid and Nimble Development Process
                  </h6>
                  <p className="nativepayment4boxtxt2">
                    We employ an agile development strategy to guarantee on-time
                    delivery of rigorously tested NFT products.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="NativePaymentsection4">
          <div className="container">
            <h2 className="nativepayment4head">
              Our NFT Marketplace Development Tech Stack
            </h2>
            <div className="row justify-content-center">
              <div className="col-md-6">
                <div className="nativepayment4box nativepayment4box5">
                  <p className="platformhead">Blockchain Platforms</p>
                  <div className="row">
                    <div className="col-3">
                      <img
                        className="ethim"
                        src="/images/innerpages/NFTDevelopment/image(7).png"
                        alt="no-data"
                      />
                    </div>
                    <div className="col-3">
                      <img
                        className="ethim2"
                        src="/images/innerpages/NFTDevelopment/image(6).png"
                        alt="no-data"
                      />
                    </div>
                    <div className="col-3">
                      <img
                        className="ethim2"
                        src="/images/innerpages/NFTDevelopment/image(5).png"
                        alt="no-data"
                      />
                    </div>
                    <div className="col-3">
                      <img
                        className="ethim2"
                        src="/images/innerpages/NFTDevelopment/image(4).png"
                        alt="no-data"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="nativepayment4box nativepayment4box5">
                  <p className="platformhead">Storage Platforms</p>
                  <div className="row justify-content-center">
                    <div className="col-3">
                      <img
                        className="ethim2"
                        src="/images/innerpages/NFTDevelopment/image(8).png"
                        alt="no-data"
                      />
                    </div>
                    <div className="col-3">
                      <img
                        className="ethim2"
                        src="/images/innerpages/NFTDevelopment/image(9).png"
                        alt="no-data"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-md-6">
                <div className="nativepayment4box nativepayment4box5">
                  <p className="platformhead">NFT Standards</p>
                  <div className="row">
                    <div className="col-3">
                      <img
                        className="ethim3"
                        src="/images/innerpages/NFTDevelopment/image(15).png"
                        alt="no-data"
                      />
                    </div>
                    <div className="col-3">
                      <img
                        className="ethim4"
                        src="/images/innerpages/NFTDevelopment/image(14).png"
                        alt="no-data"
                      />
                    </div>
                    <div className="col-3">
                      <img
                        className="ethim3"
                        src="/images/innerpages/NFTDevelopment/image(13).png"
                        alt="no-data"
                      />
                    </div>
                    <div className="col-3">
                      <img
                        className="ethim5"
                        src="/images/innerpages/NFTDevelopment/image(2).png"
                        alt="no-data"
                      />
                    </div>
                  </div>
                  <div className="row justify-content-center mt-4">
                    <div className="col-3">
                      <img
                        className="ethim4"
                        src="/images/innerpages/NFTDevelopment/image(11).png"
                        alt="no-data"
                      />
                    </div>
                    <div className="col-3">
                      <img
                        className="ethim3"
                        src="/images/innerpages/NFTDevelopment/image(10).png"
                        alt="no-data"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="nativepayment4box nativepayment4box5">
                  <p className="platformhead">Front-end Frameworks</p>
                  <div className="row">
                    <div className="col-3">
                      <img
                        className="ethim2"
                        src="/images/innerpages/NFTDevelopment/image(1).png"
                        alt="no-data"
                      />
                    </div>
                    <div className="col-3">
                      <img
                        className="ethim2"
                        src="/images/innerpages/NFTDevelopment/image(0).png"
                        alt="no-data"
                      />
                    </div>
                    <div className="col-3">
                      <img
                        className="ethim2"
                        src="/images/innerpages/NFTDevelopment/image(3).png"
                        alt="no-data"
                      />
                    </div>
                    <div className="col-3">
                      <img
                        className="ethim5"
                        src="/images/innerpages/NFTDevelopment/image(12).png"
                        alt="no-data"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="NativePaymentsection6">
          <div className="container">
            <h2 className="nativepayment6head">Our Recent Work</h2>
            <div className="row">
              <div className="col-md-5">
                <div className="nativepayment6main">
                  <img
                    className="nativepayment6im nativepayment6im2"
                    src="/images/innerpages/NativePaymentSolutions/starmetaverse.png"
                    alt="no-data"
                  />
                  <p className="nativepayment6txt">
                    We and our clients love everything about our service and
                    thanks to Diffco we have super cool website for our clients
                    and easy to use order/client management admin part for us.
                  </p>
                  <div className="media">
                    <img
                      src="/images/innerpages/NativePaymentSolutions/image4.png"
                      alt="no-data"
                      className="mr-2"
                    />
                    <div className="media-body">
                      <h5 className="mt-0 nativepayment6cli">
                        Ruslana Golunova
                      </h5>
                      <p className="nativepayment6des">
                        Co-founder, Starmetaverse
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-7">
                <div className="nativepayment6im2">
                  <img src="/images/innerpages/NativePaymentSolutions/image211.png"   alt="no-data" />
                </div>
                <NavLink
                  style={{ textDecoration: "none", cursor: "pointer" }}
                  href="https://sanjubaba.io/ "
                  target="_blank"
                >
                  <p className="checkitout">Check it out</p>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NFTdevelopement;
